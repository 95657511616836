'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Flex, HStack, SessionsLogo, SkipNavContent, SkipNavLink, useColorMode } from 'ui';

import { useIsKinde } from '@/hooks/useIsKinde/useIsKinde';

import { useViewerContext } from '../../context/ViewerContext/ViewerContext';

import { HelpMenu } from './AllLayout/components/HelpMenu';
import { SignInButton } from './AllLayout/components/SignInButton';
import { UserMenu } from './AllLayout/components/UserMenu';

import type { FC, PropsWithChildren } from 'react';

type Props = {
  showLoginHint?: boolean;
  disableHeaderLogo?: boolean;
  hideHeaderRightItems?: boolean;
};

const Logo = ({ disableHeaderLogo }: Pick<Props, 'disableHeaderLogo'>) => {
  const router = useRouter();
  if (disableHeaderLogo) {
    return (
      <div className="ml-2">
        <SessionsLogo />
      </div>
    );
  }
  const handleLogoClick = () => {
    router.replace('/');
  };

  return (
    <a aria-label="Return home" href="/" onClick={handleLogoClick}>
      <SessionsLogo />
    </a>
  );
};

const Header = ({ disableHeaderLogo, hideHeaderRightItems, showLoginHint = false }: Props) => {
  const { colorMode, setColorMode } = useColorMode();
  const { status } = useViewerContext();
  const isKinde = useIsKinde();

  useEffect(() => {
    if (!colorMode) {
      return;
    }
    setColorMode(colorMode);
  }, [colorMode]);

  return (
    <HStack justifyContent="space-between" w="full" p={{ base: '4', md: '6' }}>
      <Logo disableHeaderLogo={disableHeaderLogo} />
      {!hideHeaderRightItems && (
        <div className="flex items-center space-x-2">
          {status === 'authenticated' ? <HelpMenu /> : null}
          {status === 'authenticated' ? <UserMenu /> : null}
          {status === 'unauthenticated' && isKinde ? <SignInButton showLoginHint={showLoginHint} /> : null}
        </div>
      )}
    </HStack>
  );
};

export const MainLayout: FC<PropsWithChildren<Props>> = ({
  children,
  disableHeaderLogo,
  hideHeaderRightItems,
  showLoginHint,
}) => {
  const { t } = useTranslation();
  return (
    <Flex w="full" flexDirection="column" h={{ base: 'auto', lg: '100vh' }}>
      <SkipNavLink zIndex="banner">{t('global.skipToContent')}</SkipNavLink>
      <Header
        disableHeaderLogo={disableHeaderLogo}
        hideHeaderRightItems={hideHeaderRightItems}
        showLoginHint={showLoginHint}
      />
      <SkipNavContent />
      <Container maxW="container.xl" h="full" p={{ base: '4', md: '6', lg: '10' }}>
        {children}
      </Container>
    </Flex>
  );
};
