import { Link } from '@askable/ui/core/link';
import NextLink from 'next/link';

import type React from 'react';

export const SessionLink = ({ href, children, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <Link asChild {...props}>
      <NextLink href={href || ''}>{children}</NextLink>
    </Link>
  );
};
