'use client';

import { Button } from '@askable/ui/core/button';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/core/popover';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { SessionLink } from '@/components/SessionLink/SessionLink';

type SignInButtonProps = {
  showLoginHint: boolean;
};

export const SignInButton = ({ showLoginHint }: SignInButtonProps) => {
  const [isOpen, setIsOpen] = useState(showLoginHint);
  const { t } = useTranslation();

  return (
    <Popover open={isOpen}>
      <PopoverTrigger>
        <SessionLink href={`/api/auth/login?post_login_redirect_url=${window.location.href}`}>
          {t('global.signIn')}
        </SessionLink>
      </PopoverTrigger>
      <PopoverContent onFocusOutside={e => e.preventDefault()} align="end" className="flex w-96 flex-col space-y-4">
        <div className="space-y-2">
          <h3 className="text-lg font-bold">{t('sections.room.areYouTheFacilitator')}</h3>
          <p>
            <Trans i18nKey="sections.room.signInWithAskable">
              If you are conducting your research as a facilitator,
              <SessionLink href={`/api/auth/login?post_login_redirect_url=${window.location.href}`}>
                sign in here
              </SessionLink>
            </Trans>
          </p>
        </div>
        <div className="w-full">
          <Button onClick={() => setIsOpen(false)} className="ml-auto">
            {t('global.gotIt')}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
